.skills > li,
a {
  all: unset;
}

li {
  list-style-type: none;
  cursor: pointer;
}

.JavaScript {
  transition: 0.2s;
  color: #d7c54f;
}

.JavaScript:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #c9ba52);
}

.HTML5 {
  transition: 0.2s;
  color: #e34c26;
}

.HTML5:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #953118);
}

.CSS3 {
  transition: 0.2s;
  color: #264de4;
}

.CSS3:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #2a376b);
}
.Sass {
  transition: 0.2s;
  color: #cd6799;
}

.Sass:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #7b3d5b);
}

.SQL {
  transition: 0.2s;
  color: #1e61a8;
}
.SQL span {
  vertical-align: bottom;
}

.SQL:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #34485d);
}

.React {
  transition: 0.2s;
  color: #56c3de;
}

.React:hover {
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px #4babc3fa);
}
.Redux {
  transition: 0.2s;
  color: #764abc;
}

.Redux:hover {
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px #472c71);
}

.Bootstrap {
  transition: 0.2s;
  color: #7110f5;
}

.Bootstrap:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #440a95);
}
.Git {
  transition: 0.2s;
  color: #f1502f;
}

.Git:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #96311d);
}
.TypeScript {
  transition: 0.2s;
  color: #007acc;
}

.TypeScript:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 2px #044c7c);
}
