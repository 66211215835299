@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Roboto+Mono&display=swap");
:root {
  --main-light-color: #fefcf6;
  --secondary-light-color: #d5e5de;
  --main-dark-color: #1f3451;
  --secondary-dark-color: #082032;
  --text-dark-color: #f0f8ff;
}

body #light {
  margin: 0;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-light-color);
  color: #212529;
  display: grid;
}
body #dark {
  margin: 0;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-dark-color);
  color: var(--text-dark-color);
  display: grid;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}
.reveal {
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
.MuiPaper-root {
  background-color: #9b9797 !important;
}
.navBar {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  text-decoration: none;
}
#light .navBar {
  background-color: var(--secondary-light-color) !important;
}
#dark .navBar {
  background-color: var(--secondary-dark-color) !important;
}
.drawer_navlink {
  width: 100%;
  text-decoration: none !important;
}
.nav-link {
  display: inline-block;
  margin-right: 15px;
  color: #3d3c42;
  padding-bottom: 0.25rem;
  position: relative;
  padding: 6px;
  text-decoration: none !important;
  opacity: 0.9;
}

.nav-link::before {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #3d3c42;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-link:hover::before {
  width: 95%;
  left: 0;
}
.nav-link:hover {
  opacity: 1;
}
#dark .nav-link {
  color: var(--text-dark-color);
}
#dark .nav-link::before {
  background: var(--text-dark-color);
}

.body_container {
  margin: 50px 0px;
  padding: 100px 20px;
  box-shadow: none important!;
}

.hello_world {
  font-family: "Roboto Mono", monospace !important;
  font-size: 40px !important;
  font-weight: 600;
  transform: translateY(-100vh);
  animation: fall 0.4s forwards;
}
@keyframes fall {
  100% {
    transform: translateY(0);
  }
}

.introduce {
  font-size: 16px !important;
  margin-bottom: 0;
  transform: translateY(-100vh);
  animation: fall 0.6s forwards;
  animation-delay: 0.4;
}

.my_name {
  font-size: 45px !important;
  font-weight: 600;
  margin-top: 0;
}
.my_name .first_name {
  display: inline-block !important;
  transform: translateX(-100vw);
  animation: fall 0.5s forwards;
  animation-delay: 1s;
}
.my_name .last_name {
  display: inline-block;
  transform: translateX(-100vw);
  animation: slideInLeft 0.5s forwards;
  animation-delay: 1.4s;
}
@keyframes slideInLeft {
  100% {
    transform: translateX(0);
  }
}

.h3_faded {
  font-size: 40px !important;
  font-weight: 600;
  opacity: 0.7;
}
.text_mulish {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: Mulish, sans-serif !important;
}
.short_sum {
  margin: 20px 0;

  font-family: Mulish, sans-serif;
  font-size: 18px !important;
  font-weight: 500 !important;
}

#dark .resumeButton,
#dark .submitButton {
  background-color: var(--text-dark-color);
  color: var(--main-dark-color);
}

img.my_image {
  -webkit-animation: morph 8s ease-in-out infinite;
  margin-top: 20px;
  animation: morph 8s ease-in-out infinite;
  border: 3px solid #2d2e32;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  position: relative;
  transition: all 1s ease-in-out;
  transform-origin: center bottom;
  animation-delay: -0.1s;
}

/* keyframe animation */
@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.header {
  font-size: 45px !important;
}

article.experience_paragraph {
  border-left: 1.5px solid var(--secondary-light-color);
  padding-left: 1%;
  margin-top: 2%;
}
article.experience_paragraph:hover {
  border-left: 1.7px solid gray;
}

.date {
  font-weight: bold;
}

article a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: black;
}

/* Contact Info*/
.fourth_row {
  margin: 50px 0 50px 0;
  padding: 100px 0 100px 0;
}

.contact-left {
  margin-top: 30px;
}

.contact_info {
  margin: 30px 0 30px 0;
  padding-right: 0%;
}

.email {
  color: #db4437;
  cursor: pointer;
  max-width: 300px;
  filter: drop-shadow(1px 1px 1px #541914);
  transition: all 0.3s ease-in;
}

.linkedin {
  color: #0a66c2;
  cursor: pointer;
  max-width: 200px;
  filter: drop-shadow(1px 1px 1px #06396d);
  transition: all 0.3s ease-in;
}

.github {
  color: #171515;
  cursor: pointer;
  max-width: 200px;
  filter: drop-shadow(1px 1px 1px #000000);
  transition: all 0.3s ease-in;
}

.contact_info:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.contact_form_container {
  display: flex;
  flex-direction: column;
  margin-right: 4%;
  padding: 40px;
  background-color: #edede9 !important;
}
.contact_form_paragraph {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: Mulish, sans-serif !important;
}

#dark .contact_form_container {
  background-color: var(--secondary-dark-color) !important;
  color: var(--text-dark-color) !important;
}
#dark label,
#dark input,
#dark textarea {
  color: var(--text-dark-color);
}

.footer {
  margin-top: calc(10% + 60px);
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: var(--secondary-light-color) !important;
  padding: 10px 0;
  font-family: Mulish, sans-serif !important;
}

#dark .footer {
  background-color: var(--secondary-dark-color) !important;
  color: var(--text-dark-color);
}

.footer_box {
  flex-grow: 1;
  justify-content: center;
  display: flex;
}
